import {
	List,
	Datagrid,
	TextField,
	EditButton,
	CreateButton,
	BooleanField,
	FunctionField
} from "react-admin"
import { ToggleButton } from "./enigmaToggleButton"

export const EnigmaList = (props) => (
	<List {...props} pagination={false} actions={<CreateButton label="Ajouter" />}>
		<Datagrid>
			<TextField source="title" label="Titre de l'énigme" />
			<TextField source="type" label="Type" />
			<BooleanField source="active" />
			<EditButton label="Editer" />
			<FunctionField
				label="Activer"
				render={(record) => <ToggleButton record={record} />}
			/>
		</Datagrid>
	</List>
)
