import { useState } from "react"
import { makeStyles } from "@material-ui/core"
import {
	Create,
	SelectInput,
	FormTab,
	TabbedForm,
	TabbedFormTabs,
} from "react-admin"
import { Grid } from "@mui/material"

import { EnigmaArticleData } from "./enigmaSpecificData/enigmaArticleData"
import { EnigmaTweetData } from "./enigmaSpecificData/enigmaTweetData"
import { EnigmaFacebookData } from "./enigmaSpecificData/enigmaFacebookData"
import { EnigmaInstagramData } from "./enigmaSpecificData/enigmaInstagramData"
import { EnigmaTiktokData } from "./enigmaSpecificData/enigmaTiktokData"
import { EnigmaYoutubeData } from "./enigmaSpecificData/enigmaYoutubeData"
import { EnigmaEvents } from "./enigmaEvents/enigmaEvents"

import { EnigmaCommonData } from "./enigmaCommonData/enigmaCommonData"
import { EnigmaCaption } from "./enigmaCaption/enigmaCaption"
import { EnigmaIntro } from "./enigmaMessage/enigmaIntro"
import { EnigmaOutro } from "./enigmaMessage/enigmaOutro"
import { EnigmaClue } from "./enigmaClues/enigmaClue"
import { EnigmaCaptionClue } from "./enigmaClues/enigmaCaptionClue"
import { EnigmaTemporalClue } from "./enigmaClues/enigmaTemporalClue"
import { EnigmaExplanation } from "./enigmaExplanation/enigmaExplanation"
import { FormToolbar } from "../components/button"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
	},
	hidden: {
		display: "none"
	}
})

export const EnigmaCreate = (props) => {
	const classes = useStyles()
	const [type, setType] = useState("website")

	const SwitchEnigmaType = () => {
		switch (type) {
			case "website":
				return <EnigmaArticleData {...props} />
			case "tweet":
				return <EnigmaTweetData {...props} />
			case "facebook":
				return <EnigmaFacebookData {...props} />
			case "instagram":
				return <EnigmaInstagramData {...props} />
			case "tiktok":
				return <EnigmaTiktokData {...props} />
			case "youtube":
				return <EnigmaYoutubeData {...props} />
			default:
				return null
		}
	}

	return (
		<Create {...props}>
			<TabbedForm tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} toolbar={<FormToolbar />}>
				<FormTab label="Enigmes">
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<EnigmaCommonData />
						</Grid>
						<Grid item xs={3}>
							<SelectInput
								label="type de l'énigme"
								source="type"
								className={classes.textarea}

								choices={[
									{ id: "website", name: "Article Web" },
									{ id: "tweet", name: "Twitter" },
									{ id: "facebook", name: "Facebook" },
									{ id: "instagram", name: "Instagram" },
									{ id: "tiktok", name: "TikTok" },
									{ id: "youtube", name: "Youtube" },
									{ id: "photo", name: "Photo" },
									{ id: "rumeur", name: "Rumeur" }
								]}
								defaultValue={type}
								onChange={(e) => {
									setType(e.target.value)
								}}
							/>
							<SwitchEnigmaType type={type} />
						</Grid>
					</Grid>
				</FormTab>
				<EnigmaCaption />
				<EnigmaIntro />
				<EnigmaOutro />
				<EnigmaClue />
				<EnigmaCaptionClue />
				<EnigmaTemporalClue />
				<EnigmaEvents />
				<EnigmaExplanation />
			</TabbedForm>
		</Create>
	)
}
