import { makeStyles } from "@material-ui/core"
import { RadioButtonGroupInput, TextInput } from "react-admin"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
	}
})

export const EnigmaTweetData = () => {
	const classes = useStyles()

	return (
		<>
			<RadioButtonGroupInput
				className={classes.textarea}
				source="data.certified"
				label="Certifé?"
				choices={[
					{ id: true, name: "oui" },
					{ id: false, name: "non" }
				]}
			/>
			<TextInput
				className={classes.textarea}
				source="data.retweets"
				label="Retweets"
			/>
			<TextInput className={classes.textarea} source="data.likes" label="Likes" />
			<TextInput
				className={classes.textarea}
				source="data.comments"
				label="Commentaires"
			/>
		</>
	)
}
