import { makeStyles } from "@material-ui/core"
import { TextInput } from "react-admin"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
	}
})

export const EnigmaArticleData = () => {
	const classes = useStyles()

	return (
		<TextInput
			multiline
			minRows="3"
			className={classes.textarea}
			source="data.articleTitle"
			label="Titre de l'article"
		/>
	)
}
