import {
    Button,
    useNotify,
    useRefresh,
    useDataProvider,
    useResourceContext
} from 'react-admin'
import ToggleOn from '@material-ui/icons/ToggleOn'
import ToggleOff from '@material-ui/icons/ToggleOff'

export const ToggleButton = (props) => {
    const {
        record,
    } = props

    const resource = useResourceContext()
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    let label = 'Désactiver'
    let icon = <ToggleOn />
    let color = 'primary'
    if (!record.active) {
        label = 'Activer'
        color = 'default'
        icon = <ToggleOff />
    }

    const handleClick = async () => {
        try {
            await dataProvider.update(resource, { id: record.id, data: {
                ...record,
                active: !record.active
            } });
            notify('Action completed successfully', { type: 'info' })
            refresh()
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'warning' })
        }
    }

    return (
        <Button
            label={label}
            color={color}
            onClick={handleClick}
        >
            {icon}
        </Button>
    );
}