import { makeStyles } from "@material-ui/core"
import { TextInput } from "react-admin"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
	}
})

export const EnigmaFacebookData = () => {
	const classes = useStyles()

	return (
		<>
			<TextInput
				className={classes.textarea}
				source="data.shares"
				label="Partages"
			/>
			<TextInput
				className={classes.textarea}
				source="data.likes"
				label="Likes"
			/>
			<TextInput
				className={classes.textarea}
				source="data.comments"
				label="Commentaires"
			/>
		</>
	)
}
