import { makeStyles } from "@material-ui/core"
import { RadioButtonGroupInput, required, TextInput, NumberInput, number } from "react-admin"

import { MediaField } from "../../components/mediaField"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
	}
})

export const EnigmaCommonData = () => {
	const validateAge = [number(), required()];
	const classes = useStyles()

	return (
		<>
			<TextInput
				className={classes.textarea}
				multiline
				source="title"
				label="Titre de l'énigme"
			/>
			<RadioButtonGroupInput
				className={classes.textarea}
				label="Est-ce une vrai information"
				validate={required()}
				source="real"
				choices={[
					{ id: true, name: "oui" },
					{ id: false, name: "non" }
				]}
			/>
			<NumberInput
				className={classes.textarea}
				source="level"
				label="Niveau de difficulté"
				validate={validateAge}
			/>
			<TextInput
				className={classes.textarea}
				multiline
				source="data.source"
				label="Source de l'énigme"
			/>
			<TextInput
				className={classes.textarea}
				rows="5"
				multiline
				source="data.content"
				label="Contenu de l'énigme"
			/>
			<MediaField
				source="data.description"
				label="description"
				className={classes.textarea}
				credit={true}
				creditSource="data.description.credit"
				creditLabel="Crédit du média"
				creditClassname={classes.textarea}
				alt={true}
				altSource="data.description.alt"
				altLabel="Alt du média"
				altClassname={classes.textarea}
			/>
			<MediaField
				source="data.avatar"
				label="Avatar"
				className={classes.textarea}
				credit={true}
				creditSource="data.avatar.credit"
				creditLabel="Crédit de l'avatar"
				creditClassname={classes.textarea}
				alt={true}
				altSource="data.avatar.alt"
				altLabel="Alt de l'avatar"
				altClassname={classes.textarea}
				accept="image/*"
			/>
			<TextInput
				className={classes.textarea}
				multiline
				source="data.author"
				label="Auteur de l'énigme"
			/>
			<MediaField
				source="data.media"
				label="media"
				className={classes.textarea}
				credit={true}
				creditClassname={classes.textarea}
				creditSource="data.media.credit"
				creditLabel="Crédit de l'image"
				alt={true}
				altClassname={classes.textarea}
				altSource="data.media.alt"
				altLabel="Alt de l'image"
			/>
		</>
	)
}
